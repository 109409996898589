import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { database, storage } from '../../Config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import './addTours.css';

export default function UpdateGroupTour() {
    const { id } = useParams(); // Get group ID from the URL
    const navigate = useNavigate();

    const [groupDetails, setGroupDetails] = useState({
        tour: '',
        payment: '',
        numberOfPeople: '',
        contact: '',
        paymentMethod: '',
        imageUrl: '',
        peopleDetails: [],
    });
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Fetch group details on component mount
    useEffect(() => {
        const fetchGroupDetails = async () => {
            try {
                const groupRef = doc(database, 'Groups', id);
                const groupSnap = await getDoc(groupRef);

                if (groupSnap.exists()) {
                    setGroupDetails(groupSnap.data());
                } else {
                    setError('Group not found!');
                }
            } catch (err) {
                console.error('Error fetching group details:', err);
                setError('Failed to fetch group details.');
            }
        };

        fetchGroupDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGroupDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e) => {
        setImg(e.target.files[0]);
    };

    const handlePeopleDetailsChange = (index, field, value) => {
        const updatedPeopleDetails = [...groupDetails.peopleDetails];
        updatedPeopleDetails[index] = {
            ...updatedPeopleDetails[index],
            [field]: value
        };

        // Recalculate total received
        const totalReceived = updatedPeopleDetails.reduce(
            (acc, person) => acc + parseFloat(person.payment || 0),
            0
        );

        setGroupDetails(prev => ({
            ...prev,
            peopleDetails: updatedPeopleDetails,
            totalReceived,
            totalPending: parseFloat(prev.payment) - totalReceived
        }));
    };

    const handlePeopleCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        const currentDetails = groupDetails.peopleDetails || [];

        // Adjust people details array
        const updatedPeopleDetails = Array.from({ length: count }, (_, i) => 
            currentDetails[i] || { name: '', contact: '', payment: '' }
        );

        setGroupDetails(prev => ({
            ...prev,
            numberOfPeople: count,
            peopleDetails: updatedPeopleDetails
        }));
    };

    const handleUpdateGroup = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        setError('');

        try {
            let imageUrl = groupDetails.imageUrl;

            if (img) {
                const imageRef = ref(storage, `group-images/${v4()}`);
                const snapshot = await uploadBytes(imageRef, img);
                imageUrl = await getDownloadURL(snapshot.ref);
            }

            const groupRef = doc(database, 'Groups', id);

            // Prepare update data
            const updateData = {
                tour: groupDetails.tour,
                payment: parseFloat(groupDetails.payment),
                numberOfPeople: parseInt(groupDetails.numberOfPeople),
                contact: groupDetails.contact,
                paymentMethod: groupDetails.paymentMethod,
                imageUrl,
                peopleDetails: groupDetails.peopleDetails,
                totalReceived: groupDetails.totalReceived || 0,
                totalPending: groupDetails.totalPending || 0,
                paymentStatus: groupDetails.payment === groupDetails.totalReceived ? 'paid' : 'pending',
                updatedAt: new Date().toISOString()
            };

            await updateDoc(groupRef, updateData);

            setMessage('Group updated successfully!');
            navigate('/agent/dashboard'); // Redirect after update
        } catch (err) {
            console.error('Error updating group:', err);
            setError('Failed to update group details. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="form-container">
                        <h2 className="text-center alert-success">Update Group Tour</h2>
                        
                        {message && <div className="alert alert-success">{message}</div>}
                        {error && <div className="alert alert-danger">{error}</div>}

                        <form onSubmit={handleUpdateGroup}>
                            {/* Image Upload */}
                            <div className="form-group">
                                <label><b>Image</b></label>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                    accept="image/*"
                                    className="form-control"
                                />
                                {groupDetails.imageUrl && (
                                    <img 
                                        src={groupDetails.imageUrl} 
                                        alt="Current Group Image" 
                                        className="img-fluid mt-2" 
                                        style={{ maxWidth: '200px' }} 
                                    />
                                )}
                            </div>

                            {/* Tour Name */}
                            <div className="form-group">
                                <label><b>Tour Name</b></label>
                                <input 
                                    type="text" 
                                    name="tour"
                                    value={groupDetails.tour}
                                    onChange={handleInputChange}
                                    className="form-control" 
                                    required 
                                />
                            </div>

                            {/* Contact */}
                            <div className="form-group">
                                <label><b>Contact</b></label>
                                <input 
                                    type="number" 
                                    name="contact"
                                    value={groupDetails.contact}
                                    onChange={handleInputChange}
                                    className="form-control" 
                                    required 
                                />
                            </div>

                            {/* Total Payment */}
                            <div className="form-group">
                                <label><b>Total Payment</b></label>
                                <input 
                                    type="number" 
                                    name="payment"
                                    value={groupDetails.payment}
                                    onChange={handleInputChange}
                                    className="form-control" 
                                    required 
                                />
                            </div>

                            {/* Number of People */}
                            <div className="form-group">
                                <label><b>Number of People</b></label>
                                <input 
                                    type="number" 
                                    value={groupDetails.numberOfPeople}
                                    onChange={handlePeopleCountChange}
                                    className="form-control" 
                                    min="1"
                                    required 
                                />
                            </div>

                            {/* People Details */}
                            {Array.from({ length: parseInt(groupDetails.numberOfPeople || 0) }).map((_, index) => (
                                <div key={index} className="form-group">
                                    <label><b>Person {index + 1} Details</b></label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={groupDetails.peopleDetails[index]?.name || ''}
                                        onChange={(e) => handlePeopleDetailsChange(index, 'name', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="Contact"
                                        value={groupDetails.peopleDetails[index]?.contact || ''}
                                        onChange={(e) => handlePeopleDetailsChange(index, 'contact', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                    <input
                                        type="number"
                                        placeholder="Payment"
                                        value={groupDetails.peopleDetails[index]?.payment || ''}
                                        onChange={(e) => handlePeopleDetailsChange(index, 'payment', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                </div>
                            ))}

                            {/* Total Received */}
                            <div className="form-group">
                                <label><b>Total Received</b></label>
                                <input
                                    type="number"
                                    value={groupDetails.totalReceived || 0}
                                    className="form-control"
                                    readOnly
                                />
                            </div>

                            {/* Payment Method */}
                            <div className="form-group">
                                <label><b>Payment Method</b></label>
                                <select
                                    name="paymentMethod"
                                    value={groupDetails.paymentMethod}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    required
                                >
                                    <option value="">Select Payment Method</option>
                                    <option value="online">Online</option>
                                    <option value="cash">Cash</option>
                                </select>
                            </div>

                            {/* Submit Button */}
                            <button 
                                type="submit" 
                                className="btn btn-info btn-block mt-3"
                                disabled={loading}
                            >
                                {loading ? 'Updating...' : 'Update Group Details'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}