import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../Config";
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import Loader from "../../Components/loaderComponent/loader";
import "./agentDetails.css";

const AgentDetails = () => {
  const { id } = useParams();
  const [agent, setAgent] = useState(null);
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      await fetchAgentDetails();
      await fetchAgentTours();
      setLoading(false);
    };
    fetchDetails();
  }, []);

  // Fetch agent details from Firestore
  const fetchAgentDetails = async () => {
    try {
      const agentDoc = await getDoc(doc(database, "agent", id));
      if (agentDoc.exists()) {
        setAgent(agentDoc.data());
      } else {
        alert("Agent not found!");
      }
    } catch (error) {
      console.error("Error fetching agent details:", error);
    }
  };

  // Fetch tours for the given agent ID
  const fetchAgentTours = async () => {
    try {
      const toursQuery = query(collection(database, "Groups"), where("agentId", "==", id));
      const tourSnapshots = await getDocs(toursQuery);

      if (!tourSnapshots.empty) {
        const toursData = tourSnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedGroups = toursData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setTours(sortedGroups);
      } else {
        console.log("No tours found for this agent.");
      }
    } catch (error) {
      console.error("Error fetching agent tours:", error);
    }
  };

  // Update payment status for a specific tour
  const updatePaymentStatus = async (tourId, status) => {
    try {
      const tourRef = doc(database, "Groups", tourId);
      await updateDoc(tourRef, { paymentStatus: status });

      // Update local state
      setTours(
        tours.map((tour) =>
          tour.id === tourId ? { ...tour, paymentStatus: status } : tour
        )
      );

      alert('Payment status updated successfully!');
    } catch (error) {
      console.error('Error updating payment status:', error);
      alert('Failed to update payment status. Please try again.');
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="agent-details-container">
      <h2>Agent Details</h2>
      {agent ? (
        <div className="agent-info">
          <p>
            <strong>Name:</strong> {agent.name}
          </p>
          <p>
            <strong>Email:</strong> {agent.email}
          </p>
          <p>
            <strong>Contact:</strong> {agent.contact}
          </p>
        </div>
      ) : (
        <p>Agent not found.</p>
      )}

      <h3>Tours Managed by {agent?.name}</h3>
      {tours.length > 0 ? (
        <div className="tours-table-container">
          <table className="table table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th>Image</th>
                <th>Tour Name</th>
                <th>Total Payment</th>
                <th>Received Payment</th>
                <th>Pending Payment</th>
                <th>Number of People</th>
                <th>People Details</th>
                <th>Payment Status</th>
                <th>Status Update</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {tours.map((tour) => (
                <tr key={tour.id}>
                  <td>
                    {tour.imageUrl ? (
                      <img
                        src={tour.imageUrl}
                        alt="Group"
                        className="table-image"
                        style={{ maxWidth: '80px', maxHeight: '70px', objectFit: 'cover' }}
                      />
                      
                    ) : (
                      'No Image'
                    )}
                  </td>
                  <td>{tour.tour}</td>
                  <td>₹{tour.payment.toFixed(2)}</td>
                  <td>₹{(tour.totalReceived || 0).toFixed(2)}</td>
                  <td>₹{(tour.totalPending || 0).toFixed(2)}</td>
                  <td>{tour.numberOfPeople}</td>
                  <td>
                    <details className="people-details-container">
                      <summary className="btn btn-secondary btn-sm">View Details</summary>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tour.peopleDetails && tour.peopleDetails.length > 0 ? (
                              tour.peopleDetails.map((person, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{person.name || 'N/A'}</td>
                                  <td>{person.contact || 'N/A'}</td>
                                  <td>₹{parseFloat(person.payment || 0).toFixed(2)}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">No people details available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </details>
                  </td>
                  <td>
                    <span
                      className={`badge ${
                        tour.paymentStatus === "accepted"
                          ? "badge-success"
                          : tour.paymentStatus === "pending"
                          ? "badge-warning"
                          : tour.paymentStatus === "cancelled"
                          ? "badge-danger"
                          : "badge-secondary"
                      }`}
                    >
                      {tour.paymentStatus
                        ? tour.paymentStatus.charAt(0).toUpperCase() +
                          tour.paymentStatus.slice(1)
                        : "Pending"}
                    </span>
                  </td>
                  <td>
                    <select
                      value={tour.paymentStatus || 'pending'}
                      onChange={(e) => updatePaymentStatus(tour.id, e.target.value)}
                      className="form-control payment-status-select"
                    >
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </td>
                  <td>{new Date(tour.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No tours available for this agent.</p>
      )}
    </div>
  );
};

export default AgentDetails;