import React, { useRef, useState, useEffect } from 'react';
import { database, storage, auth, Auth } from '../../Config';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import Img from '../../qr1.jpg';
import './addTours.css';

export default function AdGroup() {
    const groupTour = useRef();
    const groupContact = useRef();

    const [img, setImg] = useState(null);
    const [message, setMessage] = useState('');
    const [agent, setAgent] = useState({ id: '', name: '' });
    const [paymentMethod, setPaymentMethod] = useState('');
    const [numPeople, setNumPeople] = useState(0);
    const [peopleDetails, setPeopleDetails] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [totalReceived, setTotalReceived] = useState(0);
    

    const groupsCollection = collection(database, 'Groups');

    useEffect(() => {
        const user = Auth.currentUser;
        if (user) {
            setAgent({ id: user.uid });
        }
    }, []);

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handlePeopleChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setNumPeople(count);
        const updatedDetails = Array.from({ length: count }, (_, i) => ({
            name: peopleDetails[i]?.name || '',
            contact: peopleDetails[i]?.contact || '',
            payment: peopleDetails[i]?.payment || '',
        }));
        setPeopleDetails(updatedDetails);
        distributeTotalPayment(totalPayment, count);
    };

    const handlePersonDetailChange = (index, field, value) => {
        const updatedDetails = [...peopleDetails];
        
        if (field === 'payment') {
            // Convert value to number, default to 0 if invalid
            const paymentValue = parseFloat(value) || 0;
            
            // Update the specific person's payment
            updatedDetails[index] = {
                ...updatedDetails[index],
                payment: value  // Keep original input
            };
            
            // Recalculate total received
            const totalReceivedAmount = updatedDetails.reduce(
                (acc, person) => acc + parseFloat(person.payment || 0),
                0
            );
            
            // Ensure total received doesn't exceed total payment
            const finalTotalReceived = Math.min(
                parseFloat(totalReceivedAmount.toFixed(2)), 
                totalPayment
            );
            
            // Update state
            setPeopleDetails(updatedDetails);
            setTotalReceived(finalTotalReceived);
        } else {
            // For name and contact fields
            updatedDetails[index] = {
                ...updatedDetails[index],
                [field]: value
            };
            
            setPeopleDetails(updatedDetails);
        }
    };
    
    const distributeTotalPayment = (total, count) => {
        if (count > 0) {
            // Round total to 2 decimal places to avoid floating-point precision issues
            total = parseFloat(total.toFixed(2));
            
            // Calculate the base amount equally distributed
            const baseAmount = parseFloat((total / count).toFixed(2));
            
            let updatedDetails = Array.from({ length: count }, (_, i) => ({
                name: peopleDetails[i]?.name || '',
                contact: peopleDetails[i]?.contact || '',
                payment: baseAmount,
            }));
            
            // Calculate the total of base amounts
            const totalBaseAmount = baseAmount * count;
            
            // Calculate the difference to distribute
            const difference = parseFloat((total - totalBaseAmount).toFixed(2));
            
            // If there's a small difference due to rounding, add it to the last person's payment
            if (difference !== 0 && updatedDetails.length > 0) {
                const lastIndex = updatedDetails.length - 1;
                updatedDetails[lastIndex].payment = parseFloat(
                    (updatedDetails[lastIndex].payment + difference).toFixed(2)
                );
            }
            
            setPeopleDetails(updatedDetails);
            
            // Update total received amount
            const totalReceivedAmount = updatedDetails.reduce(
                (acc, person) => acc + parseFloat(person.payment),
                0
            );
            
            // Ensure total received matches the original total
            setTotalReceived(parseFloat(totalReceivedAmount.toFixed(2)));
        }
    };
    
    const addGroup = async (e) => {
        e.preventDefault();
        setMessage('');
    
        // Comprehensive validation
        if (!groupTour.current.value) {
            setMessage('Please enter tour name');
            return;
        }
    
        if (!groupContact.current.value) {
            setMessage('Please enter contact number');
            return;
        }
    
        if (totalPayment <= 0) {
            setMessage('Total payment must be greater than zero');
            return;
        }
    
        if (numPeople <= 0) {
            setMessage('Number of people must be greater than zero');
            return;
        }
    
        // Additional validation to prevent submission if received amount exceeds total payment
        if (totalReceived > totalPayment) {
            setMessage('Total received cannot be greater than total payment.');
            return;
        }
    
        try {
            let imageUrl = null;
            if (img) {
                const imageRef = ref(storage, `group-images/${v4()}`);
                const snapshot = await uploadBytes(imageRef, img);
                imageUrl = await getDownloadURL(snapshot.ref);
            }
    
            const groupData = {
                tour: groupTour.current.value,
                payment: totalPayment,
                totalReceived,
                totalPending: totalPayment - totalReceived,
                numberOfPeople: numPeople,
                peopleDetails,
                contact: groupContact.current.value,
                agentId: agent.id,
                imageUrl,
                paymentMethod,
                paymentStatus: totalPayment === totalReceived ? 'paid' : 'pending',
                createdAt: new Date().toISOString(),
            };
    
            console.log('Submitting Group Data:', groupData); // Debug log
    
            const docRef = await addDoc(groupsCollection, groupData);
            console.log('Document written with ID:', docRef.id);
            
            setMessage('Group added successfully! Payment status is updated based on received payments.');
    
            // Reset form
            e.target.reset();
            setImg(null);
            setNumPeople(0);
            setPeopleDetails([]);
            setTotalPayment(0);
            setTotalReceived(0);
            groupTour.current.value = '';
            groupContact.current.value = '';
        } catch (error) {
            console.error('Error adding document:', error);
            setMessage(`Error adding group: ${error.message}`);
        }
    };

    const handleTotalPaymentChange = (e) => {
        const total = parseFloat(e.target.value) || 0;
        setTotalPayment(total);
        distributeTotalPayment(total, numPeople);
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="form-container">
                        <h2 className="text-center alert-success">Add Tours</h2>
                        {message && <div className="alert alert-info">{message}</div>}
                        <form onSubmit={addGroup}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="image"><b>Image</b></label>
                                        <input
                                            type="file"
                                            onChange={(e) => setImg(e.target.files[0])}
                                            accept="image/*"
                                            className="form-control"
                                           
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="qr">
                                        <img
                                            src={Img}
                                            alt="QR Code"
                                            className="img-fluid"
                                            style={{ maxWidth: '35%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label><b>Tour Name</b></label>
                                <input type="text" ref={groupTour} className="form-control" required />
                            </div>
                            <div className="form-group">
                                <label><b>Contact</b></label>
                                <input type="number" ref={groupContact} className="form-control" required />
                            </div>
                            <div className="form-group">
                                <label><b>Total Payment</b></label>
                                <input
                                    type="number"
                                    value={totalPayment}
                                    onChange={handleTotalPaymentChange}
                                    onFocus={() => totalPayment === 0 && setTotalPayment('')}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label><b>Number of People</b></label>
                                <input
                                    type="number"
                                    value={numPeople}
                                    onChange={handlePeopleChange}
                                    onFocus={() => numPeople === 0 && setNumPeople('')}
                                    className="form-control"
                                    min="1"
                                    required
                                />
                            </div>
                            {Array.from({ length: numPeople }).map((_, index) => (
                                <div key={index} className="form-group">
                                    <label><b>Person {index + 1} Details</b></label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={peopleDetails[index]?.name || ''}
                                        onChange={(e) => handlePersonDetailChange(index, 'name', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="Contact"
                                        value={peopleDetails[index]?.contact || ''}
                                        onChange={(e) => handlePersonDetailChange(index, 'contact', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                    <input
                                        type="number"
                                        placeholder="Payment"
                                        value={peopleDetails[index]?.payment || ''}
                                        onChange={(e) => handlePersonDetailChange(index, 'payment', e.target.value)}
                                        className="form-control mb-2"
                                        required
                                    />
                                </div>
                            ))}
                            <div className="form-group">
                                <label><b>Total Received</b></label>
                                <input
                                    type="number"
                                    value={totalReceived}
                                    className="form-control"
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label><b>Payment Method</b></label>
                                <select
                                    className="form-control"
                                    value={paymentMethod}
                                    onChange={handlePaymentMethodChange}
                                    required
                                >
                                    <option value="">Select Payment Method</option>
                                    <option value="online">Online</option>
                                    <option value="cash">Cash</option>
                                </select>
                            </div>
                            <button type="submit" className="btn btn-info btn-block mt-3">
                                Add Tour
                            </button>
                        </form>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    );
}
